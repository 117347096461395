.calc {
}

.btns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3px;
  row-gap: 3px;
}

.zero {
  grid-column: 1 / span 2;
}
