@import '../../../styles/variables.scss';

.trendContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  overflow-x: auto;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  @include d {
    flex-wrap: nowrap;
  }
}

.column {
  width: calc(100% / 4);
  padding: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 200px;

  @include t {
    width: calc(100% / 6);
  }

  @include d {
    width: calc(100% / 12);
    padding: 0.5rem;
    min-width: auto;
  }
}

.columnTrend {
  position: relative;
  border: solid 1px;
  flex-grow: 1;
  display: flex;
}

.fill {
  position: absolute;
  inset: 0;
}

.expenseTrend {
  background-color: red;
  right: 50%;
}

.averageExpenses {
  border-top: solid 1px;
  right: 50%;
}

.incomesTrend {
  background-color: blue;
  left: 50%;
}

.averageIncomes {
  border-top: solid 1px;
  left: 50%;
}

.month {
  font-size: 0.7rem;
  margin-bottom: 0;
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  margin-top: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.expense {
  color: red;
}

.income {
  color: blue;
}
