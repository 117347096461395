.resultContainer {
  display: flex;
  flex-wrap: wrap;
  margin-block: 1rem;
}

.column {
  padding: 0.5rem;
}

.list {
  max-height: 300px;
  overflow: scroll;
}
