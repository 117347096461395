.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns {
  flex-grow: 1;
  text-align: end;
}
