@import '../../../styles/variables.scss';

.header {
  padding: 10px 20px;
  box-shadow: 1px 6px 5px lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 40px;
}

.burger {
  @include d {
    display: none;
  }
}
