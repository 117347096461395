@import 'variables';

html {
  font-family: 'Roboto', sans-serif;
}

.error {
  color: red;
}

nav {
  a.active {
    text-decoration: underline;
  }
}
